import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import MainMenu from './components/menu/MainMenu';
import About from './components/Pages/About';
import Collect from './components/Pages/Collect';
import ForStudents from './components/Pages/ForStudents';
import Game from './components/Pages/Game';
import Home from './components/Pages/Home';
import Join from './components/Pages/Join';
import Letter from './components/Pages/Letter';
import New from './components/Pages/New';
import Papers from './components/Pages/Papers';
import Team from './components/Pages/Team';
import './index.css';

import indigo from '@material-ui/core/colors/indigo';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Attractiveness from './components/Pages/Attractiveness';
import Data from './components/Pages/Data';
import Importance from './components/Pages/Importance';
import Lht from './components/Pages/Lht';
import Love from './components/Pages/Love';
import OwnData from './components/Pages/OwnData';
import Pathogen from './components/Pages/Pathogen';
import Socsex from './components/Pages/Socsex';
import Sugar from './components/Pages/Sugar';
import TLS15 from './components/Pages/TLS-15';
import ResearchTLSlang from './components/Pages/tlsComponents/ResearchComponents/ResearchTLSlang';
import TestTLS from './components/Pages/tlsComponents/TestComponents/TestTLS';
import TestTLSlang from './components/Pages/tlsComponents/TestComponents/TestTLSlang';
import Validation from './components/Pages/Validation';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3c3c3c',
    },
    secondary: indigo,
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <MainMenu />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/study">
          <New />
        </Route>
        {/* <Route path="/new">
        <New />
      </Route> */}
        <Route path="/game">
          <Game />
        </Route>
        <Route path="/papers">
          <Papers />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/join">
          <Join />
        </Route>
        <Route path="/students">
          <ForStudents />
        </Route>
        <Route path="/Collect">
          <Collect />
        </Route>
        <Route path="/team">
          <Team />
        </Route>
        <Route path="/sugar_relationships">
          <Sugar />
        </Route>
        <Route path="/letter">
          <Letter />
        </Route>
        <Route path="/owndata">
          <OwnData />
        </Route>
        <Route path="/attractiveness">
          <Attractiveness />
        </Route>
        <Route path="/regLove">
          <Love />
        </Route>
        <Route path="/pathogen">
          <Pathogen />
        </Route>
        <Route path="/importance">
          <Importance />
        </Route>
        <Route path="/tls" exact>
          <TLS15 />
        </Route>
        <Route path="/tls/test/" exact>
          <TestTLS />
        </Route>
        <Route path="/tls/:lang" exact>
          <ResearchTLSlang />
        </Route>
        <Route path="/tls/test/:lang">
          <TestTLSlang />
        </Route>
        <Route path="/validation">
          <Validation />
        </Route>
        <Route path="/commitment">
          <Data />
        </Route>
        <Route path="/data">
          <Data />
        </Route>
        <Route path="/lht">
          <Lht />
        </Route>
        <Route path="/socsex">
          <Socsex />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
